exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-d-tsx": () => import("./../../../src/pages/d.tsx" /* webpackChunkName: "component---src-pages-d-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-researchers-tsx": () => import("./../../../src/pages/researchers.tsx" /* webpackChunkName: "component---src-pages-researchers-tsx" */),
  "component---src-templates-documentation-default-tsx": () => import("./../../../src/templates/documentation/default.tsx" /* webpackChunkName: "component---src-templates-documentation-default-tsx" */)
}

